import Repository from './Repository';
import RepositoryAppointment from './RepositoryAppointment'
import paginateRepository from './AllPaginateRepository';
const patient = "/patients";
const patientNew = '/master-patient'
const userPatients = "/user-patients";
const religions = "/religions";
const etnicity = "/etnicities";
const searchbyMrn = '/master-patient-mrn'
const defaultConfigs = { params: { "size": 1000, "sort": "patientName" } };
import createRequestOption from './RequestParamUtil';
import Axios from 'axios';

export default {
    get(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(patient, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    getPatient(patientId) {
        return Repository.get(`${patient}/${patientId}`);
    },
    getPatientNew(patientId) {
        return RepositoryAppointment.get(`${patientNew}/${patientId}`);
    },
    deletePatientNew(patientId) {
        return RepositoryAppointment.delete(`${patientNew}/${patientId}`);
    },
    createPatientNew(payload) {
        return RepositoryAppointment.post(`${patientNew}/`, payload);
    },
    updatePatientNew(payload) {
        return RepositoryAppointment.put(`${patientNew}`, payload);
    },
    createPatient(payload) {
        return Repository.post(`${patient}`, payload);
    },
    updatePatient(payload) {
        return Repository.put(`${patient}`, payload);
    },
    patchPatient(payload) {
        return Repository.patch(`${patient}`, payload);
    },
    deletePatient(patientId) {
        return Repository.delete(`${patient}/${patientId}`);
    },
    AbortControllerToken() {
        let cancelToken = null
        let abortCont = new AbortController()
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        cancelToken = source
        return [abortCont , cancelToken]
    },
    searchPatient (payload) {
        const config = createRequestOption(payload)
        return RepositoryAppointment.get(`/_search/patients`, config)
    },
    search: function*(payload) {
        const tokenAbort = this.AbortControllerToken()

        yield tokenAbort[0]

        const config = createRequestOption(payload);

        if (tokenAbort[1]) {
            config.cancelToken = tokenAbort[1].token
        }

        yield RepositoryAppointment.get(`/_search/patients`, config);

        tokenAbort[0].signal.addEventListener( 'abort', () => {
            if (tokenAbort[1]) {
                tokenAbort[1].cancel(`Another request was made`)
            }
        });
    },
    searchMrnByPatien (mrn) {
        return RepositoryAppointment.get(`${searchbyMrn}/${mrn}`)
    },
    searchMasterPasien: function*(params) {
        const tokenAbort = this.AbortControllerToken()

        yield tokenAbort[0]

        const config = {params};

        if (tokenAbort[1]) {
            config.cancelToken = tokenAbort[1].token
        }
        yield RepositoryAppointment.get(`${patientNew}`, config);

        tokenAbort[0].signal.addEventListener( 'abort', () => {
            if (tokenAbort[1]) {
                tokenAbort[1].cancel(`Another request was made`)
            }
        });
    },
    fetch: function*(req) {
        const tokenAbort = this.AbortControllerToken()

        yield tokenAbort[0]

        const defaultConfigs = createRequestOption(req);

        if (tokenAbort[1]) {
            defaultConfigs.cancelToken = tokenAbort[1].token
        }

        yield Repository.get(`${patient}`, defaultConfigs);

        tokenAbort[0].signal.addEventListener( 'abort', () => {
            if (tokenAbort[1]) {
                tokenAbort[1].cancel(`Another request was made`)
            }
        });
    },
    fetchNewPatient: function*(req) {
        const tokenAbort = this.AbortControllerToken()

        yield tokenAbort[0]

        const defaultConfigs = createRequestOption(req);

        if (tokenAbort[1]) {
            defaultConfigs.cancelToken = tokenAbort[1].token
        }

        yield RepositoryAppointment.get(`${patientNew}`, defaultConfigs);

        tokenAbort[0].signal.addEventListener( 'abort', () => {
            if (tokenAbort[1]) {
                tokenAbort[1].cancel(`Another request was made`)
            }
        });
    },
    getUserPatient(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(userPatients, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    getReligions(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(religions, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    getEtnicity(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(etnicity, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    }
}
