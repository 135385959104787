export default {
    mapperInfoPanelEmpty (item) {
        return {
            type: item.type,
            start: item.start,
            end: item.end,
            namaDokter: item.data.dokter.nama,
            jamMulai: item.start ? moment(item.start).format('HH:mm') : '',
            jamSelesai: item.end ? moment(item.end).format('HH:mm') : '',
            ...(item.generatedId ? {generatedId: item.generatedId} : {})
            // spesialisasi: item.
        }
    },
    mapperInfoPanel (data) {
        const type = data.status == 'CONFIRM' || data.status == 'ARRIVED' ? 'RESERVED' : data.status
        const isPaid = data.telekonsul && data.teleconsultPayment.status === 'PAID' ? true : false
        const jamMulai = data.jamMulai ?? '00:00:00'
        const isArrived = data.status == 'ARRIVED'
        return {
            data: data,
            type,
            spesialisasi: data.dokter.spesialisasi,
            start: `${data.tanggalKunjungan}T${jamMulai}Z`,
            end: `${data.tanggalKunjungan}T${data.jamSelesai}Z`,
            id: data.id,
            namaDokter: data.dokter.nama,
            dokterId: data.dokter.id,
            generatedId: data.generatedId,
            keyDokter: data.passwordDokter,
            tenantId: data.dokter.tenantId,
            namaPasien: data.namaPasien,
            patiendId: data.patiendId,
            tanggalLahir: data.tanggalLahir,
            gender: data.gender,
            telp: data.telp,
            email: data.email,
            pesan: data.pesan,
            createdBy: data.createdByName,
            createdDate: data.createdAt,
            lastModifiedBy: data.updatedAt,
            lastModifiedDate: data.updatedAt,
            jenisPenjamin: data.jenisPenjamin,
            isTeleconsult: data.telekonsul,
            isPaid,
            isArrived,
            teleconsultPayment: data.teleconsultPayment,
            price: data.price,
            ...(data.teleconsultPayment && data.teleconsultPayment.linkPatient ? {linkPasien: data.teleconsultPayment.linkPatient} : {}),
            jamMulai: data.jamMulai ? data.jamMulai.slice(0, 5) : '',
            jamSelesai: data.jamSelesai ? data.jamSelesai.slice(0, 5): ''
        }
    },
    mapperDataShowReservasiNull (resp) {
        return {
            ...resp,
        }
    },
    mapperDataShowReservasi (resp) {
        const _data = resp
        return {
            ..._data,
            patient: {
                id: _data.patiendId,
                patientName: _data.namaPasien,
                dateOfBirth: _data.tanggalLahir,
                jenisKelamin: _data.gender,
                mobilePhone1: _data.telp,
                email: _data.email,
                medicalNo: _data.nomorMedicalRecord
            },
            ...(_data.telekonsul ? {teleconsult: _data.telekonsul} : {}),
            jenisPenjamin: {nama: _data.jenisPenjamin, id: _data.jenisPenjamin},
            jamMulai: _data.jamMulai ? _data.jamMulai.slice(0, 5) : '',
            jamSelesai: _data.jamSelesai ? _data.jamSelesai.slice(0, 5): ''
        }
    },
    mapperHistoryCancelled (data) {
        return data.map(_item => {
            return {
                id: _item.generatedId,
                tanggalKunjungan: _item.tanggalKunjungan,
                createdDate: _item.createdDate,
                namaPasien: _item.namaPasien,
                tanggalLahir: _item.tanggalLahir,
                telp: _item.telp,
                cancelStatus: _item.status
            }
        })
    },
    postSendAppointment (data) {
        const genderInitial = data.patient.jenisKelamin && data.patient.jenisKelamin.initial ? data.patient.jenisKelamin.initial : (data.patient.gender ?? 'MALE')
        return {
                "namaPasien": data.namaPasien,
                "tanggalLahir": data.tanggalLahir,
                "tanggalKunjungan": data.tanggalKunjungan,
                "telp": data.telp,
                "pesan": data.pesan,
                "gender": {
                  "initial": genderInitial
                },
                "jadwal": {
                  "dokter": {
                    "id": data.dokter.id
                  }
                },
                "jenisPenjamin": {
                  "nama": data.jenisPenjamin.nama
                },
                "patient": {
                  ...(data.patient.id ? {id: data.patient.id} : {}),
                  "medicalNo": data.patient.medicalNo ?  data.patient.medicalNo: null,
                  "ssn": data.patient.ssn,
                  "email": data.patient.email
                },
                "jamMulai": data.jamMulai,
                "jamSelesai": data.jamSelesai,
                ...(data.patient && data.patient.medicalNo ? {nomorMedicalRecord: data.patient.medicalNo} : {}),
                ...(data.teleconsult ? {"teleconsult": data.teleconsult} : {}),
                ...(data.smsNotif ? {smsNotif: data.smsNotif} : {}),
                ...(data.emailNotif ? {emailNotif: data.emailNotif} : {}),
                ...(data.overrideTarif || data.teleconsult ? {"overrideTarif": parseFloat(data.overrideTarif)} : {})}
    },
    postSendAppointmentReschedule (data) {
        // console.log('2', data)
        return {
            // customMessage: 1,
            dokterId: data.dokter.id,
            ...(data.patient && data.patient.email ? {email: data.patient.email} : {}),
            ...(data.emailNotif ? {emailNotif: data.emailNotif, email: data.emailNotif} : {}),
            "gender": data.gender,
            "generatedId": data.generatedId,
            "jamMulai": data.jamMulai,
            "jamSelesai": data.jamSelesai,
            "jenisPenjamin": data.jenisPenjamin.nama,
            "namaPasien": data.patient.patientName,
            "nomorKtp": data.patient.ssn,
            "nomorMedicalRecord": data.patient.medicalNo,
            "patientId": data.patient.id,
            "pesan": data.pesan,
            ...(data.smsNotif ? {smsNotif: data.smsNotif} : {}),
            tanggalKunjungan: data.tanggalKunjungan,
            tanggalLahir: data.tanggalLahir,
            ...(data.teleconsult ? {"telekonsul": data.teleconsult} : {}),
            ...(data.patient.mobilePhone1 ? {telp: data.patient.mobilePhone1} : {}),
            ...(data.overrideTarif || data.teleconsult ? {"overrideTarif": parseFloat(data.overrideTarif)} : {})
        }
    },
    isCheckObjectGender(data) {
        if(typeof data.jenisKelamin == 'object') {
            return data.jenisKelamin.initial
        } else {
            return data.jenisKelamin
        }
    }
}