import { RepositoryFactory } from "../repositories/RepositoryFactory";
const DoctorRepository = RepositoryFactory.get("doctor");
const QueueUnitRepository = RepositoryFactory.get("queueUnit");
const ReservationRepository = RepositoryFactory.get("reservation");
import SlotJadwalType from "../constants/slot-jadwal-type-enum";
import CheckPermission from "./../services/checkPermission.service";
// import { data, param } from "jquery";
const checkPermission = new CheckPermission();
// const md5 = require("md5");
import RenderDataFullCalendar from "../services/render-fullcalendar";
import mapperData from '../services/mapperData'
// import { mergeWith } from "lodash";
const renderDataFullCalendar = new RenderDataFullCalendar()
import Vue from 'vue'
import SweetAlertTemplate from './../components/_general/SweetAlert';
const SweetAlert = Vue.extend(SweetAlertTemplate);

const countSlot = (mulai, selesai, durationSlot) => {
    const jamMulai = mulai.split(":");
    const jamSelesai = selesai.split(":");
    const minuteJamMulai =
        parseFloat(jamMulai[0] * 60) + parseFloat(jamMulai[1]);
    const minuteJamSelesai =
        parseFloat(jamSelesai[0] * 60) + parseFloat(jamSelesai[1]);
    const durationMinute = minuteJamSelesai - minuteJamMulai;
    return Math.floor(durationMinute / durationSlot);
};
const setOrder = ( type, lengthItem) => {
    if (type == SlotJadwalType.BLOCKED || type == SlotJadwalType.EMPTY) {
        return 0;
    } else if (type == SlotJadwalType.RESERVED) {
        return 1 + lengthItem;
    } else if (type == SlotJadwalType.DEFECT) {
        return 2 + lengthItem;
    } else if (type == SlotJadwalType.INTERNAL_EVENT) {
        return -1;
    } else if (type == SlotJadwalType.TELAT) {
        return -2;
    }
};
export default {
    namespaced: true,
    state: {
        isLoadFullCalendar: false,
        resources: [],
        listDokter: [],
        listReservasi: [],
        listReservasiDefect: [],
        listInternalEvent: [],
        listTelat: [],
        listWaiting: [],
        events: [],
        selectedEvent: null,
        showModalWaiting: false,
        showModalInternal: false,
        showModalBlock: false,
        showModalHelp: false,
        fromEvent: true,
        forUpdate: false,
        listEventWaiting: [],
        loadingEvent: false,
        selectedJadwal: null,
        showModalMrReg: false,
        showModalTempWa: false,
        showModalHistory: false,
        showModalTelekonsul: false,
        appoinFromDashboard: "",
        showModalWA: false,
        numberWA: "",
        newState: [],
        isTimeOutCalendar: '',
        queueUnitSlot: [],
        idReservasi: '',
        detailPanel: {},
        selectedSlot:{}
    },
    mutations: {
        SET_SELECTED_SLOT(state, {panel, evt, date}) {
            const slotReservasi = panel
            if(panel && panel.type == 'EMPTY') {
                state.selectedSlot = {
                    jamMulai: slotReservasi.jamMulai,
                    jamSelesai: slotReservasi.jamSelesai,
                    dokter: evt.data.dokter,
                    date: slotReservasi.start.slice(0, 10)
                }
            } else if (panel && panel.type == 'RESERVED') {
                state.selectedSlot = {
                    generatedId: slotReservasi.generatedId,
                    jamMulai: slotReservasi.jamMulai,
                    jamSelesai: slotReservasi.jamSelesai,
                    date: slotReservasi.start.slice(0, 10)
                 }
            } else {
                state.selectedSlot = {
                    date
                }
            }
        },
        SET_PANEL_INFO (state, res) {
            state.detailPanel = res
        },
        SET_RESERVASI_ID(state, res) {
            state.idReservasi = res
        },
        SET_QUEUE_UNIT_SLOT(state, res) {
            state.queueUnitSlot = res
        },
        SET_NEW_STATE(state, res) {
            state.newState = res
        },
        SET_LOADING_FULL_CALENDAR (state, res) {
            state.isLoadFullCalendar = res
        },
        SET_SHOW_MODAL_MRREG(state, res) {
            state.showModalMrReg = res;
        },
        SET_SHOW_MODAL_TEMPWA(state, res) {
            state.showModalTempWa = res;
        },
        SET_SHOW_MODAL_WA(state, res) {
            state.showModalWA = res;
        },
        SET_SHOW_MODAL_LINKTELEKONSUL(state, res) {
            state.showModalTelekonsul = false;
            setTimeout(() => {
                state.showModalTelekonsul = res;
            }, 200);
        },
        SET_SELECTED_JADWAL(state, res) {
            state.selectedJadwal = res;
        },
        SET_LIST_DOKTER(state, res) {
            state.listDokter = res;
        },
        SET_LIST_RESERVASI(state, res) {
            state.listReservasi = res;
        },
        SET_LIST_RESERVASI_DEFECT(state, res) {
            state.listReservasiDefect = res;
        },
        SET_LIST_INTERNAL_EVENT(state, res) {
            state.listInternalEvent = res;
        },
        SET_LIST_TELAT(state, res) {
            state.listTelat = res;
        },
        SET_RESOURCES(state, res) {
            state.resources = res;
        },
        SET_EVENTS(state, res) {
            state.events = res;
        },
        SET_APPOINT_FROM_DASHBOARD(state, res) {
            state.appoinFromDashboard = res;
        },
        SET_SELECTED_EVENT(state, res) {
            state.selectedEvent = res;
        },
        SET_SHOW_MODAL_INTERNAL(state, res) {
            state.showModalInternal = res;
        },
        SET_SHOW_MODAL_BLOCK(state, res) {
            state.showModalBlock = res;
        },
        SET_SHOW_MODAL_HELP(state, res) {
            state.showModalHelp = res;
        },
        SET_FROM_EVENT(state, res) {
            state.fromEvent = res;
        },
        SET_FOR_UPDATE(state, res) {
            state.forUpdate = res;
        },
        SET_LIST_WAITING(state, res) {
            state.listWaiting = res;
        },
        SET_SHOW_MODAL_WAITING(state, res) {
            state.showModalWaiting = res;
        },
        SET_LIST_EVENT_WAITING(state, res) {
            state.listEventWaiting = res;
        },
        SET_LOADING_EVENT(state, res) {
            state.loadingEvent = res;
        },
        SET_SHOW_MODAL_HISTORY(state, res) {
            state.showModalHistory = res;
        },
        SET_NUMBER_WA(state, res) {
            state.numberWA = res;
        }
    },
    actions: {
        async GET_SLOT_EVENT({commit, rootState, dispatch, state },  {resolve, reject}) {
        if(state.loadingEvent)return 
            let allDataSlot = []
            let isPosition = 0
            let before = 0
            let apiDokter, dokter
            commit('SET_LOADING_FULL_CALENDAR', false)
            commit("SET_LOADING_EVENT", true);
            commit("SET_EVENTS", []);  // untuk initial state
            const dokters = rootState.DoctorAppointmentStore.dokterArray
            if (!dokters.length) {
                commit("SET_RESOURCES", []);
                commit("SET_LOADING_EVENT", false);
                return 
            }
            const apiCalendar = rootState.DoctorAppointmentStore.calendarApi
            // rootState.DoctorAppointmentStore.calendarApi  == getApi
            const date = moment(rootState.DoctorAppointmentStore.date).format('YYYY-MM-DD')
            const gettersDokterOrPoli = rootState.DoctorAppointmentStore.dokter ?
                renderDataFullCalendar.pickDokterValue(rootState.DoctorAppointmentStore.dokter, SlotJadwalType.DOKTER) :
                (rootState.DoctorAppointmentStore.poli ? renderDataFullCalendar.pickDokterValue(rootState.DoctorAppointmentStore.poli, SlotJadwalType.POLI) : 
                    renderDataFullCalendar.pickDokterValue(null, SlotJadwalType.ALLSELECT)
                )

                const paramsDokter = renderDataFullCalendar.sendParamsDokter({
                    type: gettersDokterOrPoli.type,
                    id: gettersDokterOrPoli.id,
                    date
                })
                
                const dokterIds = []
                if (dokters.length) {
                    dokters.forEach(dokter => {
                        dokterIds.push(dokter.id)
                    });
                }
                let doctorLogin = localStorage.getItem('dokterId')

                if (doctorLogin) {
                    // apiDokter = () =>  new Promise((resolve, reject) => {
                    //     DoctorRepository.getCurrentDoctors( paramsDokter, resolve, reject , {type: 'id.in' , dokterIds});
                    // });
                } else {
                    const params2 = renderDataFullCalendar.sendParamsQueue2({
                        date,
                        list: dokters
                    })
                    const apiQueue = (paramQueue) => new Promise((resolve, reject) => {
                        QueueUnitRepository.geQueueUnitSlotWithQuery( paramQueue, resolve, reject );
                    });
                    const queuUnitSlot = await apiQueue(params2)
                                .then(resp => resp.data)
                    commit('SET_QUEUE_UNIT_SLOT', queuUnitSlot)
                    apiDokter = () => new Promise((resolve, reject) => {
                        const cloneQueue = structuredClone(queuUnitSlot)
                        DoctorRepository.getHolidayInHolday(paramsDokter, resolve, reject, cloneQueue);
                    });
                }
                // set ke state QUEUE_UNIT_DATA = await get api queue unit slot
                // let promise dokter adalah QUEUE_UNIT_DATA reduce/fold

                // api dokter terima promise dokter diatas untuk replace (paginate all dokter)
                try {
                    // create column doctor appointment && mapping dokter in jadwal
                    dokter = await apiDokter()
                            .then(async (_resp) => {
                                let mapResources = dokters.map(item => {
                                    return {
                                        id: item.id,
                                        title: item.nama
                                    }
                                })

                                commit("SET_RESOURCES", mapResources);
                                _resp.data.forEach(item => {
                                    if(item.holiday) {
                                        const holiday = renderDataFullCalendar.setHoliday({
                                            date,
                                            id: item.id,
                                            data: {
                                                hexColor: 'gray',
                                                type: SlotJadwalType.HOLIDAY,
                                                holidayRemarks: item.holidayRemarks
                                            }
                                        })
                                        allDataSlot.push(holiday)
                                    }
                                })
                                return _resp.data
                            })
                            .then(_resp => renderDataFullCalendar.getDokterByJadwal(_resp, date))
                    /*
                    dokter didapat bedasarkan list jadwal nya
                    set slot menggunakan SET_SLOT_WITH_DOKTER
                    */
                    let dispatchDoneCount = 0
                    for(let i = 0; i < dokter.length; i++) {
                        dispatch('SET_SLOT_WITH_DOKTER', {
                            doctorLogin,
                            dokter: dokter[i],
                            date
                    //   })
                        }).then(res => {
                            allDataSlot.push(...res)
                            commit('SET_NEW_STATE', allDataSlot)
                            dispatchDoneCount++
                        })
                        // allDataSlot.push(...data)
                        // commit("SET_EVENTS", [ ...state.events, ...data ]);
                    }
                    
                    let isCheck = 0
                    if(state.isTimeOutCalendar) clearInterval(state.isTimeOutCalendar)
                        state.isTimeOutCalendar = setInterval(async () => {
                            isCheck++
                            before = isPosition
                            isPosition = allDataSlot.length
                            if(isPosition == before && isCheck == 5){
                                commit("SET_LOADING_EVENT", false);
                                resolve()
                                clearInterval(state.isTimeOutCalendar)
                            }
                            if(isPosition == before){
                                return false
                            }
                            if(isPosition > 0 ) {
                                isCheck = 0
                                let dataSlotRender = allDataSlot.slice(before, isPosition)   
                                if (isPosition > SlotJadwalType.MAXIMAL_LOAD) {
                                    dataSlotRender = await renderDataFullCalendar.bisnisSlot({
                                        data: dataSlotRender,
                                        before,
                                        max: SlotJadwalType.MAXIMAL_LOAD
                                    })
                                }

                                apiCalendar.addEventSource({
                                    events: dataSlotRender
                                })
                                if (SlotJadwalType.MAXIMAL_LOAD <=isPosition) {
                                    clearInterval(state.isTimeOutCalendar)
                                    new SweetAlert().showConfirm({
                                        swalTitle: 'Info!',
                                        swalText: 'Data belum Terload Semua, Ingin Lanjutkan',
                                        swalType: 'info',
                                        textConfirm: 'Lanjutkan',
                                        textCancel: 'Cancel',
                                        onConfirmButton: function () {
                                            dispatch('SHOW_ALL')
                                        },
                                        onCancelButton: function () {
                                            // 
                                        }
                                    })
                                }
                            }
                            if (dispatchDoneCount == dokter.length) {
                                resolve()
                            }
                            commit("SET_LOADING_EVENT", false);
                        }, 1000)
                        
                } catch(error) {
                    // console.log('error 1', error)
                    commit("SET_LOADING_EVENT", false);
                    reject(error)
                }
                
        },
        SET_SLOT_WITH_DOKTER({state, commit}, {doctorLogin, dokter, date}) {
            return new Promise(async (resolve, reject) => {

            let apiQueue
            if (doctorLogin) {
                apiQueue = (paramQueue) => new Promise((resolve, reject) => {
                    QueueUnitRepository.geQueueUnitSlotWithQuery( paramQueue, resolve, reject );
                });
            } else {
                apiQueue = (paramQueue) => new Promise((resolve, reject) => {
                    const queuUnitSlot = structuredClone(state.queueUnitSlot)
                    const dokters = structuredClone(dokter)
                    const data = queuUnitSlot.filter(item => dokters.jadwal.some(item2 => item2.id == item.id ))
                    resolve(data)
                    // QueueUnitRepository.geQueueUnitSlotWithQuery( paramQueue, resolve, reject );
                });
            }
            // set params untuk menarik data queue-unit-slot
            const params = renderDataFullCalendar.sendParamsQueue({
                        date,
                        list: dokter ? dokter.jadwal : []
                    })
                // getEmptySlot ambil data kosong   
                // slotJadwal ambil seluruh slot jadwal yang tersedia
            const [getEmptyAllSlot, slotJadwal] =  await Promise.all([
                renderDataFullCalendar.getSlotEmptyJadwalWithDuration(dokter, date),
                apiQueue(params)
                .then(resp => resp)
                .then(_data => {
                    const _resp = Array.isArray(_data) ? _data : _data.data
                    return renderDataFullCalendar.getSlotReservedJadwal(_resp, date, dokter.jadwal)
                })
            ]);        
            const mergeItem = await renderDataFullCalendar.mergeAllSlot(slotJadwal, getEmptyAllSlot,dokter.jadwal.length)
            const objEvent1 = mergeItem.map((item) => {
                const data = {
                    ...item,
                    jadwal: item.data,
                    reservasi: item.reservasi ? item.reservasi: null,
                    type: item.type,
                    hexColor: item.hexColor,
                    widthMerge: item.widthMerge,
                    index: item.index
                }
                return {
                    title: JSON.stringify(data),
                    resourceId: item.id,
                    start: item.start,
                    end: item.end,
                    displayOrder: item.index,
                    classNames: ['bg-transparent', 'none-border']
                }
            })
            resolve(objEvent1)                  
        })
        },
        SHOW_ALL({commit, state, rootState}) {
            commit("SET_LOADING_EVENT", true);
            const apiCalendar = rootState.DoctorAppointmentStore.calendarApi
            apiCalendar.removeAllEvents()
            apiCalendar.addEventSource({
                events: state.newState
            })
            commit("SET_LOADING_EVENT", false);
        },
        GET_INFO_PANEL_EMPTY({commit}, obj) {
            const data = mapperData.mapperInfoPanelEmpty(obj)
            commit('SET_PANEL_INFO', data)
        },
        GET_INFO_PANEL({commit}, generatedId) {
             ReservationRepository.getReservationAppointmentBackoffice(generatedId)
                .then( _resp => {
                    const dataPanel = mapperData.mapperInfoPanel(_resp.data)
                    commit('SET_PANEL_INFO', dataPanel)
                })
        },
        async GET_EVENT({ commit, state, dispatch, rootState, rootGetters }) {
            try {
                commit("SET_LOADING_EVENT", true);
                commit("SET_EVENTS", []);
                // dapat resoureces alias grouping
                const resource =
                    rootGetters[
                        "DoctorAppointmentStore/GET_LIST_RESOURCE_DAY_CALENDAR"
                    ];
                const mapResources = resource.map(x => {
                    return {
                        id: x.id,
                        title: x.nama
                    };
                });
                commit("SET_RESOURCES", mapResources);
                //init state dari storenya DoctorAppointmentStore
                var momentDay = moment(rootState.DoctorAppointmentStore.date);
                var dokter = rootState.DoctorAppointmentStore.dokter;
                var poli = rootState.DoctorAppointmentStore.poli;
                
                // Buat parameter untuk hit api dokter
                var paramDokter = {
                    "tanggalMulai.equals": momentDay.format("YYYY-MM-DD"),
                    "tanggalSelesai.equals": momentDay.format("YYYY-MM-DD")
                };
                // Buat paramater untuk hit api reservasi
                var paramQueue = {
                    // "reservasiStatuses": "NEW, CONFIRM, ARRIVED",
                    tanggalKunjunganMulai: momentDay.format("YYYY-MM-DD"),
                    tanggalKunjunganSelesai: momentDay.format("YYYY-MM-DD")
                };
                if (poli) {
                    paramDokter.tujuanRujukanId = poli.id;
                    paramQueue.tujuanRujukanIds = poli.id;
                }
                // Kalau sudah ada filter id.in nya
                if (dokter) {
                    paramDokter["id.in"] = dokter.id;
                    paramQueue.dokterIds = dokter.id;
                }

                //Hit API
                let doctorLogin = checkPermission.isCanAccess(
                    "DokterResource.GET.Dokter.Current"
                );
                let apiDokter;
                let apiQueue;
                const dokters = rootState.DoctorAppointmentStore.dokterArray
                let dokterIds = []
                dokters.forEach(dokter => {
                    dokterIds.push(dokter.id)
                });
                if (doctorLogin) {
                    apiDokter = new Promise((resolve, reject) => {
                        DoctorRepository.getCurrentDoctors(
                            paramDokter,
                            resolve,
                            reject,
                            {type: 'id.in' , dokterIds}
                        );
                    });
                    apiQueue = new Promise((resolve, reject) => {
                        QueueUnitRepository.getDoctorQueueSlot(
                            paramQueue,
                            resolve,
                            reject,
                            {type: 'id.in' , dokterIds}
                        );
                    });
                } else {
                    apiDokter = new Promise((resolve, reject) => {
                        DoctorRepository.get(paramDokter, resolve, reject , {type: 'id.in' , dokterIds});
                    });
                    apiQueue = new Promise((resolve, reject) => {
                        QueueUnitRepository.geQueueUnitSlot(
                            paramQueue,
                            resolve,
                            reject,
                            {type: 'id.in' , dokterIds}
                        );
                    });
                }
                // Time to hit api, jangan lupa klau api reservasi sudah benar tambah apiReservasi dan responseReservasi
                const [responseDokter, responseQueue] = await Promise.all([
                    apiDokter,
                    apiQueue
                ]);
                // sementara buat mockup klau dokter terpilih maka list dokter nya
                const listdokter = responseDokter.data;
                commit("SET_LIST_DOKTER", listdokter);
                const map = helpers.splitingReservation(
                    responseQueue.data,
                    momentDay.format("YYYY-MM-DD")
                );
                try {
                    const data1 = await renderDataFullCalendar.splitingReservation(responseQueue.data, momentDay)
                                        .then(_data => renderDataFullCalendar.getSlotInJadwals(_data))
                } catch {
                    console.log('gagal')
                }
                // anggap dapat response queue sebagai berikut lalu split berdasarkan reservasi(APPOINTMENT), INTERNAL_EVENT, TELAT , WAITING
                // reservasi
                commit("SET_SELECTED_JADWAL", map.todayJadwal);
                commit("SET_LIST_RESERVASI", map.reservasiAppointment);
                commit("SET_LIST_INTERNAL_EVENT", map.internalEvent);
                commit("SET_LIST_TELAT", map.listTelat);
                commit("SET_LIST_RESERVASI_DEFECT", map.reservasiDefect);
                commit("SET_LIST_WAITING", map.reservasiWaiting);
                dispatch("CONSTRUCT_EVENT");
            } catch (err) {
                commit("SET_LOADING_EVENT", false);
                toastr.error("Error get data");
            }
        },
        async CONSTRUCT_EVENT({ commit, state, dispatch, rootState }) {
            // Buat Slot jadwal
            // const renderDataFullCalendar.changeColorBorderSlot = (id, dokter) => {
            //     let hexMd5 = toMd5(dokter);
            //     for (let i = 0; i < id; i++) {
            //         hexMd5 = toMd5(hexMd5);
            //     }
            //     return hexMd5.slice(0, 6);
            // };
            // const toMd5 = str => {
            //     return md5(str);
            // };

            const checkDataItem = (data1, data2) => {
                const slot1Mulai = moment(data1.jamMulai, 'HH;mm')
                const slot1Selesai = moment(data1.jamSelesai, 'HH;mm')
                const slot2Mulai = moment(data2.jamMulai, 'HH;mm')
                const slot2Selesai = moment(data2.jamSelesai, 'HH;mm')
                if(
                    moment(slot1Mulai).isBetween(slot2Mulai, slot2Selesai , null, '[)') || 
                    moment(slot1Selesai).isBetween(slot2Mulai, slot2Selesai, null, '()') ||
                    moment(slot2Mulai).isBetween(slot1Mulai, slot1Selesai, null, '[)') || 
                    moment(slot2Selesai).isBetween(slot1Mulai, slot1Selesai, null, '()')
                ) {
                    return true
                } else {
                    return false
                }
            }

            var listEvent = [];
            var date = rootState.DoctorAppointmentStore.date;
            var day = moment(date)
                .locale("en")
                .format("dddd")
                .toUpperCase();
            let backedDefect = [];
            // let reservedMap = new Map();
            let dataJadwal = [];
            state.listDokter.forEach(x => {
                // Cari apakah dokter mempunyai jadwal pada tanggal / hari tersebut
                const jadwal = x.jadwals.find(y => y.hari == day);
                if (jadwal) {
                    // Kalau ada jadwalnya maka
                    jadwal.jam.forEach((j, indexBorder) => {
                        dataJadwal.push({ jam: j, indexBorder });
                        // dapatkan duration perjadwal j.duration, tapi kita asumsi pake dummy dl
                        const durationSlot =
                            j.durationInMinute != null
                                ? j.durationInMinute
                                : 15;
                        // cari jumlah slot
                        const totalSlot = countSlot(
                            j.jamMulai,
                            j.jamSelesai,
                            durationSlot
                        );
                        // jika ada slot
                        let counterSlot = 1;
                        var startMoment = moment(
                            `${date} ${j.jamMulai}`,
                            "YYYY-MM-DD HH:mm"
                        );
                        while (counterSlot <= totalSlot) {
                            var start = startMoment.clone();
                            var end = startMoment.add(durationSlot, "m");
                            let objectdata = {
                                jadwal: j,
                                type: SlotJadwalType.EMPTY,
                                reservasi: null,
                                hexColor: `#${renderDataFullCalendar.changeColorBorderSlot(indexBorder, x.id)}`
                            };
                            // cari block jadwal dgn array
                            if (j.slotIndexBlocked) {
                                let found = j.slotIndexBlocked.find(
                                    el => el == counterSlot - 1
                                );
                                if (found == 0 || found) {
                                    objectdata.type = SlotJadwalType.BLOCKED;
                                }
                            }

                            if (objectdata.jadwal.statusJadwalDokters[0]) {
                                objectdata.jadwal.statusJadwalDokters.forEach(
                                    x => {
                                        let selectedDate = new Date(
                                            rootState.DoctorAppointmentStore.date
                                        );
                                        var statusJadwalDate = new Date(
                                            x.tanggal
                                        );
                                        if (
                                            selectedDate.setHours(0, 0, 0, 0) ==
                                            statusJadwalDate.setHours(
                                                0,
                                                0,
                                                0,
                                                0
                                            )
                                        ) {
                                            objectdata.type =
                                                SlotJadwalType.BLOCKED;
                                        }
                                    }
                                );
                            }

                            // Cari apakah slot ada reservasi;
                            var haveReservasation = state.listReservasi.find(
                                reservasi => {
                                    const slotStart = moment(
                                        `${reservasi.tanggalKunjungan} ${
                                            reservasi.jamMulai
                                        }`,
                                        "YYYY-MM-DD HH:mm"
                                    ).format("YYYY-MM-DDTHH:mm:ss");
                                    const slotSelesai = moment(
                                        `${reservasi.tanggalKunjungan} ${
                                            reservasi.jamSelesai
                                        }`,
                                        "YYYY-MM-DD HH:mm"
                                    ).format("YYYY-MM-DDTHH:mm:ss");
                                    if (
                                        j.id == reservasi.jadwal.id &&
                                        slotStart ==
                                            start.format(
                                                "YYYY-MM-DDTHH:mm:ss"
                                            ) &&
                                        slotSelesai ==
                                            end.format("YYYY-MM-DDTHH:mm:ss")
                                    )
                                        return reservasi;
                                }
                            );
                            // slot ini ada reservasinya
                            if (haveReservasation) {
                                objectdata.type = SlotJadwalType.RESERVED;
                                objectdata.reservasi = haveReservasation;
                                // reservedMap.set(haveReservasation.id, true);
                            } else {
                                // Mengecek apakah slot kena block atau tidak
                                var blocked = false;
                                var paramStart = state.listReservasiDefect.find(
                                    reservasi => {
                                        if (
                                            j.id == reservasi.jadwal.id &&
                                            moment(
                                                `${
                                                    reservasi.tanggalKunjungan
                                                } ${reservasi.jamMulai}`
                                            ).isBetween(start, end)
                                        )
                                            return reservasi;
                                    }
                                );
                                var paramEnd = state.listReservasiDefect.find(
                                    reservasi => {
                                        if (
                                            j.id == reservasi.jadwal.id &&
                                            moment(
                                                `${
                                                    reservasi.tanggalKunjungan
                                                } ${reservasi.jamSelesai}`
                                            ).isBetween(start, end)
                                        )
                                            return reservasi;
                                    }
                                );
                                var paramSame = state.listReservasiDefect.find(
                                    reservasi => {
                                        if (
                                            j.id == reservasi.jadwal.id &&
                                            moment(
                                                `${
                                                    reservasi.tanggalKunjungan
                                                } ${reservasi.jamMulai}`
                                            ).isSame(start, end)
                                        )
                                            return reservasi;
                                    }
                                );
                                // apakah ada reservasi diantara slot
                                if (paramStart || paramEnd || paramSame) {
                                    
                                    //  kalau ada diantara slot
                                    let queue =
                                        paramStart || paramEnd || paramSame;
                                    let dash = [...new Set([queue.queueId])];
                                    blocked = true;
                                    backedDefect = [...backedDefect, ...dash];
                                    if (paramSame) {
                                        blocked = false;
                                        objectdata.type =
                                            SlotJadwalType.BLOCKED;
                                    }
                                } else {
                                    // check apakah ada irisan
                                    var versionOne = state.listReservasiDefect.find(
                                        reservasi => {
                                            const reservasiStart = moment(
                                                `${
                                                    reservasi.tanggalKunjungan
                                                } ${reservasi.jamMulai}`
                                            );
                                            const reservasiEnd = moment(
                                                `${
                                                    reservasi.tanggalKunjungan
                                                } ${reservasi.jamSelesai}`
                                            );
                                            if (
                                                j.id == reservasi.jadwal.id &&
                                                reservasiStart.isSameOrAfter(
                                                    start
                                                ) &&
                                                reservasiStart.isBefore(end) &&
                                                reservasiEnd.isAfter(
                                                    moment(end)
                                                )
                                            ) {
                                                return reservasi;
                                            }
                                        }
                                    );
                                    var versionTwo = state.listReservasiDefect.find(
                                        reservasi => {
                                            const reservasiStart = moment(
                                                `${
                                                    reservasi.tanggalKunjungan
                                                } ${reservasi.jamMulai}`
                                            );
                                            const reservasiEnd = moment(
                                                `${
                                                    reservasi.tanggalKunjungan
                                                } ${reservasi.jamSelesai}`
                                            );
                                            if (
                                                j.id == reservasi.jadwal.id &&
                                                reservasiEnd.isAfter(start) &&
                                                reservasiEnd.isSameOrBefore(
                                                    end
                                                ) &&
                                                reservasiStart.isBefore(
                                                    moment(start)
                                                )
                                            ) {
                                                return reservasi;
                                            }
                                        }
                                    );
                                    var versionTree = state.listReservasiDefect.find(
                                        reservasi => {
                                            const reservasiStart = moment(
                                                `${
                                                    reservasi.tanggalKunjungan
                                                } ${reservasi.jamMulai}`
                                            );
                                            const reservasiEnd = moment(
                                                `${
                                                    reservasi.tanggalKunjungan
                                                } ${reservasi.jamSelesai}`
                                            );
                                            if (
                                                j.id == reservasi.jadwal.id &&
                                                reservasiStart.isBefore(
                                                    start
                                                ) &&
                                                reservasiEnd.isAfter(end)
                                            ) {
                                                return reservasi;
                                            }
                                        }
                                    );
                                    blocked =
                                        versionOne || versionTwo || versionTree;
                                }
                                if (blocked) {
                                    let queueId = blocked.queueId;
                                    let bash = [...new Set([queueId])];
                                    backedDefect = [...backedDefect, ...bash];
                                    objectdata.type = SlotJadwalType.BLOCKED;
                                }
                            }
                           
                            if (objectdata.type == "RESERVED") {
                                let checkData = false
                                for(let i = 0; i < state.listReservasiDefect.length; i++) {
                                    const dataItem = state.listReservasiDefect
                                    checkData = checkDataItem(objectdata.reservasi, dataItem[i])
                                    if(checkData){
                                        objectdata.widthMerge = checkData
                                        break
                                    }
                                }
                            }
                            var objectEvent = {
                                resourceId: x.id,
                                title: JSON.stringify(objectdata),
                                start: start.format("YYYY-MM-DDTHH:mm:ss"),
                                end: end.format("YYYY-MM-DDTHH:mm:ss"),
                                displayOrder: setOrder(objectdata.type),
                                classNames: ["bg-transparent"]
                            };
                            if(!paramSame && !paramStart && !paramEnd ) {
                                listEvent.push(objectEvent);
                            }
                           
                            counterSlot++;
                        }
                    });
                }

                // Apakah ada waiting list
                var filterWaitingList = state.listWaiting.filter(z => {
                    if (x.id == z.jadwal.dokter.id) {
                        return z;
                    }
                });
                if (state.listWaiting.length > 0) {
                    let objectdata = {
                        type: SlotJadwalType.WAITING,
                        dokter: x,
                        waitingCount: filterWaitingList.length
                    };
                    var objectEvent = {
                        resourceId: x.id,
                        title: JSON.stringify(objectdata),
                        start: state.listWaiting[0].date,
                        allDay: true,
                        classNames: ["bg-transparent"]
                    };
                    listEvent.push(objectEvent);
                }
            });
            // reservasi yang defect
            state.listReservasiDefect.forEach((x,index) => {
                const className = dataJadwal.find(
                    el => el.jam.id == x.jadwal.id
                );
                let checkData = false
                for(let i = index+1; i < state.listReservasiDefect.length; i++) {
                    const dataItem = state.listReservasiDefect
                    checkData = checkDataItem(x, dataItem[i])
                    if(checkData)break;
                }
                // const result = backedDefect.filter(id => id == x.queueId);
                // if (result.length > 0) {
                const start = moment(
                    `${x.tanggalKunjungan} ${x.jamMulai}`,
                    "YYYY-MM-DD HH:mm"
                ).format("YYYY-MM-DDTHH:mm:ss");
                const end = moment(
                    `${x.tanggalKunjungan} ${x.jamSelesai}`,
                    "YYYY-MM-DD HH:mm"
                ).format("YYYY-MM-DDTHH:mm:ss");
                let objectdata = {
                    jadwal: x.jadwal,
                    type: SlotJadwalType.DEFECT,
                    reservasi: x,
                    widthMerge: checkData,
                    hexColor: className
                        ? `#${renderDataFullCalendar.changeColorBorderSlot(
                              className.indexBorder,
                              className.jam.dokter.id
                          )}`
                        : "#000000"
                    // className: className
                    //     ? `index-border-${renderDataFullCalendar.changeColorBorderSlot(className.indexBorder)}`
                    //     : "index-border-2"
                };
                var objectEvent = {
                    resourceId: x.jadwal.dokter.id,
                    title: JSON.stringify(objectdata),
                    start: start,
                    end: end,
                    displayOrder: setOrder(objectdata.type),
                    classNames: ["bg-transparent"]
                };

                listEvent.push(objectEvent);
                // }
            });
            // Internal Event
            state.listInternalEvent.forEach(x => {
                const className = dataJadwal.find(
                    el => el.jam.id == x.jadwal.id
                );
                const start = moment(
                    `${x.tanggal} ${x.jamMulai}`,
                    "YYYY-MM-DD HH:mm"
                ).format("YYYY-MM-DDTHH:mm:ss");
                const end = moment(
                    `${x.tanggal} ${x.jamSelesai}`,
                    "YYYY-MM-DD HH:mm"
                ).format("YYYY-MM-DDTHH:mm:ss");
                //selectedjadwalnya salah

                let objectdata = {
                    jadwal: x.jadwal,
                    type: SlotJadwalType.INTERNAL_EVENT,
                    reservasi: x,
                    hexColor: className
                        ? `#${renderDataFullCalendar.changeColorBorderSlot(
                              className.indexBorder,
                              className.jam.dokter.id
                          )}`
                        : "#000000"
                    // className: className
                    //     ? `index-border-${renderDataFullCalendar.changeColorBorderSlot(className.indexBorder)}`
                    //     : "index-border-2"
                };
                var objectEvent = {
                    resourceId: x.jadwal.dokter.id,
                    title: JSON.stringify(objectdata),
                    start: start,
                    end: end,
                    displayOrder: setOrder(objectdata.type),
                    classNames: ["bg-transparent"]
                };
                listEvent.push(objectEvent);
            });

            // TELAT
            state.listTelat.forEach(x => {
                const className = dataJadwal.find(
                    el => el.jam.id == x.jadwal.id
                );
                const start = moment(
                    `${x.tanggal} ${x.jamMulai}`,
                    "YYYY-MM-DD HH:mm"
                ).format("YYYY-MM-DDTHH:mm:ss");
                const end = moment(
                    `${x.tanggal} ${x.jamSelesai}`,
                    "YYYY-MM-DD HH:mm"
                ).format("YYYY-MM-DDTHH:mm:ss");
                let objectdata = {
                    jadwal: x.jadwal,
                    type: SlotJadwalType.TELAT,
                    reservasi: x,
                    hexColor: className
                        ? `#${renderDataFullCalendar.changeColorBorderSlot(
                              className.indexBorder,
                              className.jam.dokter.id
                          )}`
                        : "#000000"
                    // className: className
                    //     ? `index-border-${renderDataFullCalendar.changeColorBorderSlot(className.indexBorder)}`
                    //     : "index-border-2"
                };
                var objectEvent = {
                    resourceId: x.jadwal.dokter.id,
                    title: JSON.stringify(objectdata),
                    start: start,
                    end: end,
                    displayOrder: setOrder(objectdata.type),
                    classNames: ["bg-transparent"]
                };
                listEvent.push(objectEvent);
            });
            commit("SET_EVENTS", listEvent);
            let result;
            if (listEvent.length > 0 && state.appoinFromDashboard) {
                result = listEvent.filter(el => {
                    let title = JSON.parse(el.title);
                    if ((title.type = "RESERVED" && title.reservasi !== null)) {
                        return title.reservasi.id == state.appoinFromDashboard;
                    }
                });
                if (result[0]) {
                    let rlt = JSON.parse(result[0].title);
                    rlt.start = result[0].start;
                    rlt.end = result[0].end;
                    commit("SET_SELECTED_EVENT", rlt);
                }
            }
            commit("SET_LOADING_EVENT", false);
        },
        GET_LIST_EVENT_WAITING_LIST(
            { commit, state, dispatch, rootState },
            id
        ) {
            commit("SET_LIST_EVENT_WAITING", []);
            // var listReservasiWl = state.listWaiting.filter(z => id == z.jadwal.dokter.id);
            var temp = [];
            // state.selectedJadwal.forEach(y => {
            //     // var index = temp.findIndex(y => y.id == x.jadwal.id);
            //     // if(index < 0){
            //     //     const jadwal = {...x.jadwal};
            //     //     jadwal.reservasi = []
            //     //     temp.push(jadwal);
            //     //     index = temp.length - 1;
            //     // }
            //     // temp[index].reservasi.push(x);
            //     y.content  = state.listWaiting.filter( x => y.id == x.reservasi.jadwal.id)
            //     y.content.forEach(z => {
            //         z.reservasi.jadwal = y
            //     })
            // })

            //     //logic disini gunananya me return list sesuai id BUKAN SEMUANYA
            //masukan waiting list kedalam jadwal, terus return jadwalnya
            const selectedJadwal = JSON.parse(JSON.stringify(state.selectedJadwal))
            const listWaiting = JSON.parse(JSON.stringify(state.listWaiting))
            let jadwal = selectedJadwal.filter(y => {
                if (id == y.dokter.id) {
                    let waitings =listWaiting.filter(x => {
                        if (y.id == x.jadwal.id) {
                            return x;
                        }
                    });
                    // y.content = waitings;
                    return y;
                }
            });
            commit("SET_LIST_EVENT_WAITING", jadwal);
        },
        SHOW_LIST_WAITING({commit}, data) {
            commit('SET_LIST_EVENT_WAITING', data)
        }
    }
};
